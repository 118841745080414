import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestParams } from "@type/common/Url"
import { PostTimelineRead } from "@type/models/Post"

export type PostContext = {
	posts: PostTimelineRead[]
	currentPostId?: string
	loadMorePosts?: () => void
	loadMorePostsParams?: { url: string; page: number } & RequestParams
	isFetchingNewPosts?: boolean
	shouldDisplayUnreadOnly?: boolean
}

export type PostContextSet = {
	[postContextId in string]: PostContext
}

const initialState: PostContextSet = {}

const slice = createSlice({
	name: "postContext",
	initialState,
	reducers: {
		registerPostContext: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				posts: PostTimelineRead[]
			}>
		) => {
			const { postContextId, posts } = action.payload
			const stateClone = state
			stateClone[postContextId] = { posts }
			return stateClone
		},
		unregisterPostContext: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
			}>
		) => {
			const { postContextId } = action.payload
			const stateClone = state
			delete stateClone[postContextId]
			return stateClone
		},
		setCurrentPostId: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				currentPostId: string | undefined
			}>
		) => {
			const { postContextId, currentPostId } = action.payload
			const stateClone = state
			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].currentPostId = currentPostId
			}
			return stateClone
		},
		setPosts: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				posts: PostTimelineRead[]
			}>
		) => {
			const { postContextId, posts } = action.payload
			const stateClone = state

			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].posts = posts
			}
			return stateClone
		},
		setLoadMorePosts: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				loadMorePosts: () => void
			}>
		) => {
			const { postContextId, loadMorePosts } = action.payload
			const stateClone = state
			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].loadMorePosts = loadMorePosts
			}
			return stateClone
		},
		setLoadMorePostsParams: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				loadMorePostsParams: PostContext["loadMorePostsParams"]
			}>
		) => {
			const { postContextId, loadMorePostsParams } = action.payload
			const stateClone = state
			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].loadMorePostsParams = loadMorePostsParams
			}
			return stateClone
		},
		setIsFetchingNewPosts: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				isFetchingNewPosts: boolean
			}>
		) => {
			const { postContextId, isFetchingNewPosts } = action.payload
			const stateClone = state
			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].isFetchingNewPosts = isFetchingNewPosts
			}
			return stateClone
		},
		setShouldDisplayUnreadOnly: (
			state: PostContextSet,
			action: PayloadAction<{
				postContextId: string
				shouldDisplayUnreadOnly: boolean
			}>
		) => {
			const { postContextId, shouldDisplayUnreadOnly } = action.payload
			const stateClone = state
			if (Object.keys(state).includes(postContextId)) {
				// @ts-expect-error
				stateClone[postContextId].shouldDisplayUnreadOnly =
					shouldDisplayUnreadOnly
			}
			return stateClone
		}
	}
})

export const postContextReducer = slice.reducer
export const {
	registerPostContext,
	unregisterPostContext,
	setCurrentPostId,
	setPosts,
	setLoadMorePosts,
	setLoadMorePostsParams,
	setIsFetchingNewPosts,
	setShouldDisplayUnreadOnly
} = slice.actions
