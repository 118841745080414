import React, { FC, createContext, useState } from "react"
import { PatronRoleRead } from "@type/models/Patron"
import { StudentUserRead } from "@type/models/Student"

type PatronRegistrationContextType = {
	patronJoinCode?: string
	setPatronJoinCode: (patronJoinCode: string) => void
	student: StudentUserRead | undefined
	setStudent: (student: StudentUserRead) => void
	patronId: PatronRoleRead["id"] | undefined
	setPatronId: (patronId: PatronRoleRead["id"]) => void
}

export const PatronRegistrationContext =
	createContext<PatronRegistrationContextType>(
		{} as PatronRegistrationContextType
	)

type Props = {
	children: React.ReactNode
}
export const PatronRegistrationContextProvider: FC<Props> = ({ children }) => {
	const [patronJoinCode, setPatronJoinCode] = useState<string>()
	const [patronId, setPatronId] = useState<PatronRoleRead["id"]>()
	const [student, setStudent] = useState<StudentUserRead>() // StudentSimpleReadでも良いかも

	return (
		<PatronRegistrationContext.Provider
			value={{
				patronJoinCode,
				setPatronJoinCode,
				student,
				setStudent,
				patronId,
				setPatronId
			}}
		>
			{children}
		</PatronRegistrationContext.Provider>
	)
}
