import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type CurrentScreenStateType = {
	screenName: string | undefined
	params?: { [key in string]: any }
}

const initialState: CurrentScreenStateType = {
	screenName: undefined,
	params: undefined,
}

const slice = createSlice({
	name: "currentScreen",
	initialState,
	reducers: {
		setCurrentScreen: (
			state: CurrentScreenStateType,
			action: PayloadAction<CurrentScreenStateType>
		) => {
			return action.payload
		},
	},
})

export const currentScreenReducer = slice.reducer
export const { setCurrentScreen } = slice.actions
