import { useErrorToast } from "@hooks/useErrorToast"
import { CourseSimpleRead } from "@type/models/Course"
import useSWR from "swr"

type ReturnType = {
	course: CourseSimpleRead | undefined
	isLoading: boolean
}

const getKey = (courseId?: string) =>
	courseId && courseId !== "undefined"
		? `/api/v1/courses/${courseId}/summary`
		: null

export const useSimpleCourse = (courseId?: string): ReturnType => {
	const {
		data: course,
		error,
		isValidating,
	} = useSWR<CourseSimpleRead>(getKey(courseId))
	const { addErrorToast } = useErrorToast()
	// useEffect(() => {
	// 	error &&
	// 		addErrorToast({
	// 			error: error,
	// 			baseText: "授業の取得に失敗しました",
	// 			statusCodesToIgnore: [403]
	// 		})
	// }, [error])

	return {
		course,
		isLoading: isValidating,
	}
}
