import { firestore } from "@apis/firebase"
import { doc, onSnapshot } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"

type Params = {
	classroomIds: (string | undefined | null)[]
}

export const useClassroomsScreenLockStatus = ({ classroomIds }: Params) => {
	const [classroomScreenLockStatusMap, setClassroomScreenLockStatusMap] =
		useState<{
			[key: string]: boolean
		}>({})
	const [errorMap, setErrorMap] = useState<{ [key: string]: string }>({})

	useEffect(() => {
		if (!classroomIds || classroomIds.length === 0) {
			setErrorMap({})
			return
		}

		const unsubscribeFunctions: (() => void)[] = []

		classroomIds.forEach((classroomId) => {
			if (!classroomId) {
				setErrorMap((prev) => ({
					...prev,
					[classroomId || "unknown"]: "Classroom ID is not available",
				}))
				return
			}

			const classroomDocRef = doc(firestore, "classrooms", classroomId)

			const unsubscribe = onSnapshot(
				classroomDocRef,
				(docSnap) => {
					if (docSnap.exists()) {
						const data = docSnap.data()
						setClassroomScreenLockStatusMap((prev) => ({
							...prev,
							[classroomId]: data.isScreenLocked || false,
						}))
						setErrorMap((prev) => ({
							...prev,
							[classroomId]: "",
						}))
					} else {
						setErrorMap((prev) => ({
							...prev,
							[classroomId]: "Classroom not found",
						}))
					}
				},
				(err) => {
					console.error(
						`Error fetching classroom data for ${classroomId}:`,
						err,
					)
					setErrorMap((prev) => ({
						...prev,
						[classroomId]: "Failed to fetch classroom data",
					}))
				},
			)

			unsubscribeFunctions.push(unsubscribe)
		})

		// クリーンアップ：コンポーネントがアンマウントされた時やclassroomIdsが変更された時にリスナーを解除
		return () => {
			unsubscribeFunctions.forEach((unsubscribe) => unsubscribe())
		}
	}, [classroomIds])

	const forceUpdateStatus = useCallback(
		({
			classroomId,
			isScreenLocked,
		}: {
			classroomId: string
			isScreenLocked: boolean
		}) => {
			const prev = { ...classroomScreenLockStatusMap }
			setClassroomScreenLockStatusMap((prev) => ({
				...prev,
				[classroomId]: isScreenLocked,
			}))
			return () => {
				setClassroomScreenLockStatusMap(prev)
			}
		},
		[classroomScreenLockStatusMap],
	)

	return { classroomScreenLockStatusMap, errorMap, forceUpdateStatus }
}
