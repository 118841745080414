import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AnnouncementRead } from "@type/models/Announcements"

export type LocalAnnouncementViewType = {
	announcementView: { [key in AnnouncementRead["id"]]: string } // key にannouncementId、valueにそれをみた最終時刻のstring
	newAnnouncementViewCounts: number
}

const initialState: LocalAnnouncementViewType = {
	announcementView: {},
	newAnnouncementViewCounts: 0
}

const slice = createSlice({
	name: "localAnnouncementView",
	initialState,
	reducers: {
		appendLocalAnnouncementView: (
			state: LocalAnnouncementViewType,
			action: PayloadAction<{ announcementId: string }>
		) => {
			const { announcementId } = action.payload
			state.announcementView[announcementId] = new Date().toISOString()
			return state
		},
		incrementNewAnnouncementViewCount: (
			state: LocalAnnouncementViewType,
			action: PayloadAction
		) => {
			const prevCount = state.newAnnouncementViewCounts || 0
			state.newAnnouncementViewCounts = prevCount + 1
			return state
		},
		resetNewAnnouncementViewCount: (
			state: LocalAnnouncementViewType,
			action: PayloadAction
		) => {
			state.newAnnouncementViewCounts = 0
			return state
		}
	}
})

export const localAnnouncementViewReducer = slice.reducer
export const {
	appendLocalAnnouncementView,
	incrementNewAnnouncementViewCount,
	resetNewAnnouncementViewCount
} = slice.actions
