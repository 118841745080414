import { PatronRegistrationContextProvider } from "@contexts/PatronRegistrationContext"
import { StudentRegistrationContextProvider } from "@contexts/StudentRegistrationContext"
import { TeacherRegistrationContextProvider } from "@contexts/TeacherRegistrationContext"
import React, { FC } from "react"

type Props = {
	children: React.ReactNode
}

export const RegistrationProviderWrapper: FC<Props> = ({ children }) => (
	<TeacherRegistrationContextProvider>
		<StudentRegistrationContextProvider>
			<PatronRegistrationContextProvider>
				{children}
			</PatronRegistrationContextProvider>
		</StudentRegistrationContextProvider>
	</TeacherRegistrationContextProvider>
)
