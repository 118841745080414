import { UseToastOptions } from "@chakra-ui/react"

export const ToastPosition = {
	TOP: "top",
	// CENTER: "center",
	BOTTOM: "bottom"
} as const

const ToastPositionList = [
	ToastPosition.TOP,
	// ToastPosition.CENTER,
	ToastPosition.BOTTOM
]
export type ToastPositionType = typeof ToastPositionList[number]

export const ToastType = {
	INFO: "info",
	WARN: "warn",
	ERROR: "error",
	SUCCESS: "success"
	// CONGRATS: "congrats"
} as const

const ToastTypeList = [
	ToastType.INFO,
	ToastType.WARN,
	ToastType.ERROR,
	ToastType.SUCCESS
	// ToastType.CONGRATS
]
export type ToastTypeType = typeof ToastTypeList[number]

export const ToastSize = {
	MEDIUM: "medium",
	SMALL: "small",
	LARGE: "large"
} as const

const ToastSizeList = [ToastSize.MEDIUM, ToastSize.SMALL, ToastSize.LARGE]
export type ToastSizeType = typeof ToastSizeList[number]

export type addToastType = (args: Partial<ToastType>) => void

export type ToastType = {
	text: string
	position: ToastPositionType
	strictPosition?: UseToastOptions["position"]
	type: ToastTypeType
	visible: boolean
	duration: number
	size: ToastSizeType
	onDismiss: () => void
	onClick?: () => void
	removeToast: () => void
	toastId: number
	image?: ImageData
}
