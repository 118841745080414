import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
	UnAuthenticatedUserType,
	UserAuthType,
	UserWithRoleIdRead
} from "@type/models/User"

export type UserInfoType = Omit<
	UserAuthType | UnAuthenticatedUserType,
	"tokens"
>
export const isUserAuthType = (
	userInfo: UserInfoType
): userInfo is UserAuthType => !!userInfo.user

const initialState: UserInfoType = {
	user: undefined
}

const slice = createSlice({
	name: "userInfo",
	initialState,
	reducers: {
		setUserInfo: (
			state: UserInfoType,
			action: PayloadAction<UserWithRoleIdRead>
		) => {
			return { user: action.payload }
		},
		clearUserInfo: () => initialState
	}
})

export const authReducer = slice.reducer
export const { setUserInfo, clearUserInfo } = slice.actions
