export const extractQueryStringValueFromCurrentUrl = (key: string) => {
	const currentUrl = window.location.href
	const urlObj = new URL(currentUrl)
	const value = urlObj.searchParams.get(key)
	return value
}

type Value = string | number | (string | number)[]

export const queryString2Object = (path: string) => {
	const adhocUrl = "https://class-cloud.jp" + path
	const urlObj = new URL(adhocUrl)
	const params: { [key in string]: Value } = {}
	urlObj.searchParams.forEach((value, key) => {
		if (params.hasOwnProperty(key)) {
			const existingValue = params[key]
			if (Array.isArray(existingValue)) {
				params[key] = [...existingValue, value]
			} else {
				params[key] = [existingValue, value]
			}
		} else {
			params[key] = value
		}
	})
	return params
}
