import { RootState } from "@stores/index"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export const useUpdateUrlQueryString = () => {
	const queryString = useSelector((state: RootState) => state.queryString)
	const router = useRouter()

	useEffect(() => {
		updateUrl()
	}, [queryString])

	const updateUrl = async () => {
		const currentUrl = window.location.href
		const urlObj = new URL(currentUrl)
		// urlObj.searchParams.forEach((value, key) => urlObj.searchParams.delete(key))

		Object.keys(queryString).map((key) => {
			urlObj.searchParams.delete(key)
			// @ts-expect-error
			urlObj.searchParams.append(key, queryString[key])
		})
		if (urlObj.search === "") return
		// await sleep(3000)
		try {
			await router.replace(urlObj.href, undefined, { scroll: false })
		} catch (error) {
			console.error(error)
		}
	}
}
