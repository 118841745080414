/* tslint:disable */
/* eslint-disable */
/**
 * ClassCloud API
 * For better development
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosInstance } from "axios"
import queryString from "query-string"
import snakecaseKeys from "snakecase-keys"

import { CONFIG } from "@constants/const"
import { convertToCamelCase, convertToSnakeCase } from "@helpers/caseConvert"
import { ccDecrypt } from "@helpers/encrypt"

const API_HOST = CONFIG.API_HOST
export const ASYNC_BASE_PATH = CONFIG.ASYNC_API_HOST.replace(/\/+$/, "")
export const BASE_PATH = API_HOST.replace(/\/+$/, "")
const CUSTOM_HEADER = { HTTP_CLASS_CLOUD_LOG: true }

globalAxios.defaults.baseURL = BASE_PATH
// globalAxios.defaults.withCredentials = CONFIG.ENVIRONMENT === "local"
globalAxios.defaults.withCredentials = true
globalAxios.defaults.headers.common = {
	"Content-Type": "application/json",
}
globalAxios.interceptors.request.use(async (config) => {
	const isAbsoluteUrl =
		config.url?.startsWith("https://") || config.url?.startsWith("http://")
	const isTrustedOrigin =
		!isAbsoluteUrl ||
		config.url?.startsWith(API_HOST) ||
		config.url?.startsWith(CONFIG.ASYNC_API_HOST)

	if (window.classcloud?.tokens && isTrustedOrigin) {
		const tokens = ccDecrypt(window.classcloud.tokens)
		config.headers = {
			...config.headers,
			Authorization: `JWT ${tokens.access}`,
		}
	}
	// const firebaseIdToken = await getUserToken()
	if (isTrustedOrigin) {
		config.headers = {
			...config.headers,
			...CUSTOM_HEADER,
		}
		// if (firebaseIdToken) {
		// 	config.headers = {
		// 		...config.headers,
		// 		"X-Id-Token": `${firebaseIdToken}`
		// 	}
		// }
	}
	config.data = canJSONParse(config.data)
		? JSON.stringify(snakecaseKeys(JSON.parse(config.data), { deep: true }))
		: config.data

	return config
})
globalAxios.interceptors.response.use(
	(response) => {
		const data = convertToCamelCase(response.data)
		return { ...response, data }
	},
	(error) => {
		if (error?.response?.data) {
			const data = convertToCamelCase(error.response.data)
			error.response.data = data
		}
		return Promise.reject(error)
	},
)
globalAxios.defaults.paramsSerializer = (params) => {
	const params_ = convertToSnakeCase(params)

	return queryString.stringify(params_, { arrayFormat: "none" })
}

const canJSONParse = (obj: any) => {
	let canParse = true
	try {
		JSON.parse(obj)
	} catch (e) {
		canParse = false
	}
	return canParse
}

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
	csv: ",",
	ssv: " ",
	tsv: "\t",
	pipes: "|",
}

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
	url: string
	options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
	protected configuration: Configuration | undefined

	constructor(
		configuration?: Configuration,
		protected basePath: string = BASE_PATH,
		protected axios: AxiosInstance = globalAxios,
	) {
		if (configuration) {
			this.configuration = configuration
			this.basePath = configuration.basePath || this.basePath
		}
	}
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
	name: "RequiredError" = "RequiredError"
	constructor(public field: string, msg?: string) {
		super(msg)
	}
}
