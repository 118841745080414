export type ServerErrors<T extends string> = {
	[key in T]: string | string[] | undefined
} & {
	nonFieldErrors: string | string[] | undefined
}

export type ServerErrorResponse<T extends string> = {
	response: {
		data?: ServerErrors<T>
		status: number
	}
}

export function isHandleableServerError<T extends string>(
	error: any
): error is ServerErrorResponse<T> {
	return !!error?.response?.data
}
