import axios from "axios"
import React, { FC } from "react"
import { SWRConfig } from "swr"

const fetcher = ( url: string ) =>
	axios
		.get( url )
		.then( ( res ) => {
			return res.data
		} )
		.catch( ( error ) => {
			console.warn( "error", error, url )
			throw error
		} )

type Props = {
	children: React.ReactNode
} & React.ComponentProps<typeof SWRConfig>[ "value" ]

export const CCSWRConfig: FC<Props> = ( { children, ...restValueProps } ) => (
	<SWRConfig
		value={ {
			fetcher,
			onErrorRetry: ( error, key, config, revalidate, { retryCount } ) => {
				if ( [ 400, 403, 404, 500 ].includes( error.status ) ) return
				if ( retryCount >= 3 ) return
				setTimeout( () => revalidate( { retryCount: retryCount + 1 } ), 5000 )
			},
			refreshInterval: 0,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			...restValueProps,
		} }
	>
		{ children }
	</SWRConfig>
)
