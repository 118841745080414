import {
	Center,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	VStack,
} from "@chakra-ui/react"
import { useClassroomsScreenLockStatus } from "@features/screenLock/useClassroomsScreenLockStatus"
import { useUser } from "@hooks/useUser"

const Empty: string[] = []

export const ScreenLock = () => {
	const user = useUser()
	const { classroomScreenLockStatusMap } = useClassroomsScreenLockStatus({
		classroomIds: user?.studentClassroomIdsInThisNendo || Empty,
	})

	const isScreenLocked = Object.values(classroomScreenLockStatusMap).some(
		(isLocked) => isLocked,
	)

	return isScreenLocked ? (
		<Modal
			size="full"
			isOpen={isScreenLocked}
			onClose={() => {}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			isCentered
		>
			<ModalOverlay>
				<ModalContent>
					<ModalBody>
						<Center h="90vh">
							<VStack bg="#fff" rounded="md" alignItems="center">
								<Text>がめんろっくちゅうです</Text>
								<Text fontWeight={"bold"} fontSize={"2xl"} pb={3}>
									画面ロック中です
								</Text>
							</VStack>
						</Center>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	) : null
}
