import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
	name: "reset",
	initialState: {},
	reducers: {
		clearAllStoreIncludingPersists() {},
	},
})
export const resetReducer = slice.reducer
export const { clearAllStoreIncludingPersists } = slice.actions
