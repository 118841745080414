import { isSafari } from "react-device-detect"

export const getIsWebView = () => {
	const userAgent = window.navigator.userAgent.toLowerCase()
	return (
		!!userAgent.includes("wv") ||
		!!window.ReactNativeWebView ||
		!!window.classcloud?.tokens
	)
}

export type DeviceType = {
	isWebView: boolean
	isiOS: boolean
	isAndroid: boolean
	isPwa: boolean
	isPC: boolean
}

const defaultDevice = {
	isWebView: false,
	isiOS: false,
	isAndroid: false,
	isPwa: false,
	isPC: false,
}
export const detectDevice = (): DeviceType => {
	if (typeof window === "undefined") return defaultDevice
	const isWebView = getIsWebView()
	let isPwa =
		window.matchMedia("(display-mode: standalone)").matches ||
		(window.navigator as any).standalone
	const uALower = window.navigator.userAgent.toLowerCase()
	const isNewiPad = uALower.includes("macintosh") && "ontouchend" in document
	const isiPad = /(iPad)/i.test(navigator.userAgent)
	const isiOS =
		!!navigator.userAgent.match(/(iPhone|iPad|iPod)/) || isNewiPad || isiPad
	const isAndroid = !!navigator.userAgent.match(/(Android)/)
	const isPC = !(isiOS || isAndroid)
	// if (
	// 	!isPwa &&
	// 	window.screen.height - document.documentElement.clientHeight < 80
	// ) {
	// 	//PWAだとアドレスバーがないので
	// 	isPwa = true
	// }
	return {
		isWebView,
		isiOS,
		isAndroid,
		isPwa,
		isPC,
	}
}

export const isTouchScreen = () => {
	if (typeof navigator === "undefined") return false
	if (navigator.maxTouchPoints > 0) {
		return true
	}
	// @ts-ignore
	if (navigator?.msMaxTouchPoints && 0 < navigator?.msMaxTouchPoints) {
		return true
	}
	if (window.matchMedia("(pointer:coarse)").matches) {
		return true
	}
	// if ("ontouchstart" in window) {
	// 	console.log("3")
	// 	return true
	// }
	if (0 < navigator.maxTouchPoints) {
		return true
	}
	// @ts-ignore
	if (0 < navigator.msMaxTouchPoints) {
		return true
	}

	return false
}

export { isSafari }
