// Import the functions you need from the SDKs you need
import { CONFIG } from "@constants/const"
import { initializeApp } from "firebase/app"
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = CONFIG.FIREBASE_CONFIG

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
export const firestore = getFirestore(app)
export const getUserToken = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
			if (user) {
				const token = await getIdToken(user)
				resolve(token)
			} else {
				console.log("User not logged in")
				resolve(null)
			}
			unsubscribe()
		})
	})
}
// setPersistence(firebaseAuth, browserSessionPersistence)
