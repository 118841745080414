import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "./../stores/index"
import { UserInfoType, clearUserInfo, setUserInfo } from "@stores/userInfo"
import { UserAuthType } from "@type/models/User"

type ReturnType = {
	userInfo: UserInfoType
	setUserInfo: (userInfo: UserAuthType) => void
	clearUserInfo: () => Promise<void>
}

export const useAuth = (): ReturnType => {
	const dispatch = useDispatch()
	const userInfo = useSelector((state: RootState) => state.userInfo)

	return {
		userInfo,
		setUserInfo: useCallback((userInfo: UserAuthType) => {
			const { user } = userInfo
			dispatch(setUserInfo(user))
		}, []),
		clearUserInfo: useCallback(async () => {
			dispatch(clearUserInfo())
		}, [])
	}
}
