import { AccountsApi } from "@apis/typescript-axios"
import { useAuth } from "@hooks/useAuth"
import { useCallOnceOnMount } from "@hooks/useCallOnceOnMount"
import { useRedirectIfNeeded } from "@hooks/useRedirectIfNeeded"
import { useRouter } from "next/router"
import { useState } from "react"
import { useSWRConfig } from "swr"

const getAccountApi = () => {
	return new AccountsApi()
}

export const useInitialRedirect = () => {
	const router = useRouter()
	const [isCheckAuthDone, setIsCheckAuthDone] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>()
	const { setUserInfo, clearUserInfo } = useAuth()
	const { cache } = useSWRConfig()
	const { redirect } = useRedirectIfNeeded()

	useCallOnceOnMount(() => {
		checkAuth()
	})

	const checkAuth = async () => {
		try {
			const api = getAccountApi()
			const { data } = await api.checkAuth()
			setUserInfo(data)
			setIsCheckAuthDone(true)
			redirect(data)
		} catch (error: any) {
			const statusCode = error?.response?.status
			const isNotLoggedIn = statusCode === 401
			if (isNotLoggedIn) {
				clearUserInfo()
				// @ts-ignore
				cache.clear?.()
				console.log("error", error)
				const pathname = window.location.pathname

				if (
					pathname === "/email_verify" ||
					pathname === "/launch_app" ||
					pathname === "/sales_chat_bot" ||
					pathname === "/invited_guest_teacher" ||
					pathname === "/login" ||
					pathname === "/server_check" ||
					pathname === "/terms" ||
					pathname === "/privacy_policy" ||
					pathname === "/about" ||
					pathname === "/password/reset" ||
					pathname === "/password/reset/confirm"
					// NOT_REDIRECT_PATHS_EVEN_NOT_IF_LOGGED_IN.includes(pathname)
				) {
				} else if (pathname === "/" || pathname === "") {
					router.push("/registration")
				} else {
					router.push("/login?callback=" + pathname)
				}

				setIsCheckAuthDone(true)
			} else {
				navigator.onLine
					? setErrorMessage(
							`不明なエラーが起きました\nステータスコード: ${statusCode}, ${JSON.stringify(
								error?.response?.data,
							)}`,
					  )
					: setErrorMessage(
							"オフライン環境のようです\n電波状況を確かめてください",
					  )
				// setErrorMessage(insertLineBreaks(JSON.stringify(error)))
			}
		}
	}

	return { isCheckAuthDone, errorMessage }
}

// function insertLineBreaks(str: string): string {
// 	const maxLength = 50

// 	let result = ""

// 	for (let i = 0; i < str.length; i += maxLength) {
// 		result += str.substring(i, i + maxLength) + "\n"
// 	}

// 	return result
// }
