import useSWR from "swr"
const getKey = () => `/api/v1/sso_check`

export type ProviderId = "google.com" | "microsoft.com"

export const useGetAuthProviderId = () => {
	const {
		data,
		mutate,
		isValidating: isLoading,
	} = useSWR<{
		userAuthProviderId: ProviderId | null
		schoolAuthProviderId: ProviderId | null
	}>(getKey(), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	})

	return {
		data,
		refetch: mutate,
		isLoading,
	}
}
