import {
	Box,
	Center,
	CenterProps,
	Flex,
	IconButton,
	VStack
} from "@chakra-ui/react"
import { CloseIcon } from "@icons/CloseIcon"
import { colors } from "@styles/colors"
import React, { memo } from "react"

type Props = {
	hideCloseButton?: boolean
	onCloseButtonClick?: () => void
	children: React.ReactNode
	bg?: CenterProps["bg"]
}

const ScrollableModalContent: React.NamedExoticComponent<Props> = memo(
	({ hideCloseButton, onCloseButtonClick, children, bg }) => {
		return (
			<Center
				p={{ base: 2, md: 8 }}
				py={{ base: 4, md: 12 }}
				w="100%"
				alignSelf="center"
				bg={bg}
			>
				<VStack w="100%" bg={bg || "#ffffff"} rounded="md">
					{!hideCloseButton && onCloseButtonClick && (
						<Box position="absolute" right={4} top={4} zIndex={2}>
							<IconButton
								aria-label="deselect image"
								variant="solid"
								rounded="full"
								bg={colors.blackAlpha[700]}
								_active={{ bg: colors.blackAlpha[500] }}
								_hover={{ bg: colors.blackAlpha[400] }}
								zIndex={2}
								onClick={onCloseButtonClick}
								icon={<CloseIcon fontSize={24} color={"#fff"} />}
							/>
						</Box>
					)}
					<Flex
						px={{ base: 2, md: 8 }}
						py={{ base: 2, md: 4 }}
						w="100%"
						style={{
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center"
						}}
					>
						{children}
					</Flex>
				</VStack>
			</Center>
		)
	}
)
ScrollableModalContent.displayName = "ScrollableModalContent"
export { ScrollableModalContent }
