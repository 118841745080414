import { SchoolRead } from "@type/models/School"
import { TeacherRoleRead } from "@type/models/Teacher"
import React, { FC, createContext, useState } from "react"

type TeacherRegistrationContextType = {
	joinCode: string
	setJoinCode: (joinCode: string) => void
	school: SchoolRead | undefined
	setSchool: (classroom: SchoolRead) => void
	studentId: TeacherRoleRead["id"] | undefined
	setTeacherId: (studentId: TeacherRoleRead["id"]) => void
}

export const TeacherRegistrationContext =
	createContext<TeacherRegistrationContextType>(
		{} as TeacherRegistrationContextType
	)

type Props = {
	children: React.ReactNode
}
export const TeacherRegistrationContextProvider: FC<Props> = ({ children }) => {
	const [joinCode, setJoinCode] = useState("")
	const [studentId, setTeacherId] = useState<TeacherRoleRead["id"]>()
	const [school, setSchool] = useState<SchoolRead>() // ClassroomSimpleReadでも良いかも

	return (
		<TeacherRegistrationContext.Provider
			value={{
				joinCode,
				setJoinCode,
				// classroom,
				school,
				setSchool,
				studentId,
				setTeacherId
			}}
		>
			{children}
		</TeacherRegistrationContext.Provider>
	)
}
