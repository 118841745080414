import { AccountsApi, DefaultApi, SchoolsApi } from "@apis/typescript-axios"
import { Button, HStack, Text, VStack } from "@chakra-ui/react"
import { ScrollableModalContent } from "@components/ScrollableModalContent"
import { useAuth } from "@hooks/useAuth"
import { useCCToast } from "@hooks/useCCToast"
import { useErrorToast } from "@hooks/useErrorToast"
import { useModal } from "@hooks/useModal"
import { useUser } from "@hooks/useUser"
import { colors } from "@styles/colors"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

const getApi = () => new SchoolsApi()

export const ForceSetSchoolTotalTermsModal: FC = () => {
	const { addToast } = useCCToast()
	const { addErrorToast } = useErrorToast()
	const [isSetting, setIsSetting] = useState(false)
	const { ModalWrapper, openModal, closeModal } = useModal({
		modalProps: {
			closeOnOverlayClick: false,
			closeOnEsc: false,
		},
	})
	const user = useUser()
	const { setUserInfo } = useAuth()

	useEffect(() => {
		if (user?.schoolTotalTerms !== null) return
		if (!user.isTeacher || !user.teacherSchoolId) return
		openModal()
	}, [user])

	const reportSet = useCallback(
		async (totalTerms: 2 | 3) => {
			try {
				await new DefaultApi().postProxySlackApi({
					slackUrl:
						"https://hooks.slack.com/services/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",
					text:
						"🎷 学期を設定しました！" +
						JSON.stringify({
							name: user?.fullName,
							school: user?.teacherSchoolId,
							totalTerms,
						}),
				})
			} catch (error) {
				console.error(error)
			}
		},
		[user],
	)

	const updateTotalTerms = useCallback(
		async (totalTerms: 2 | 3) => {
			try {
				setIsSetting(true)
				await getApi().updateSchool({ totalTerms })
				const { data } = await new AccountsApi().checkAuth()
				await setUserInfo(data)
				window.alert("ご協力ありがとうございました。")
				await reportSet(totalTerms)
				closeModal()
			} catch (error) {
				addErrorToast({ error, baseText: "失敗しました" })
				console.error(error)
			} finally {
				setIsSetting(false)
			}
		},
		[addToast, reportSet, setUserInfo, closeModal, addErrorToast],
	)

	const Content = useMemo(
		() => (
			<VStack spacing={3} p={10}>
				<ScrollableModalContent>
					<Text fontSize={"2xl"} fontWeight={"bold"}>
						あなたの学校は何学期制ですか？
					</Text>
					{isSetting && (
						<Text py={3} color={colors.ui.primary[500]}>
							設定中...
						</Text>
					)}
					<HStack spacing={10} pt={10}>
						<Button w="100px" h="100px" onClick={() => updateTotalTerms(2)}>
							<Text fontSize={"2xl"}>2</Text>
							学期制
						</Button>
						<Button w="100px" h="100px" onClick={() => updateTotalTerms(3)}>
							<Text fontSize={"2xl"}>3</Text>学期制
						</Button>
					</HStack>
				</ScrollableModalContent>
			</VStack>
		),
		[isSetting, updateTotalTerms],
	)

	const Modal = useMemo(
		() => <ModalWrapper hideCloseButton>{Content}</ModalWrapper>,
		[ModalWrapper, Content],
	)

	return Modal
}
