export type FirebaseConfig = {
	apiKey: string
	authDomain: string
	projectId: string
	storageBucket: string
	messagingSenderId: string
	appId: string
	measurementId?: string
}

export const LOCAL_FIREBASE_CONFIG: FirebaseConfig = {
	apiKey: "AIzaSyCH5faZjgzCZM-9F0FX-VpaJ8UKdOXU5Gg",
	authDomain: "local-auth.class-cloud.jp",
	projectId: "localclasscloud",
	storageBucket: "localclasscloud.appspot.com",
	messagingSenderId: "416317604735",
	appId: "1:416317604735:web:165997dca4d48f348f104a"
}

export const STG_FIREBASE_CONFIG: FirebaseConfig = {
	apiKey: "AIzaSyAp4IN4j_fSU09-nXBZsLgq2-SvXDDDhd4",
	authDomain: "stg-auth.class-cloud.jp",
	projectId: "stgclasscloud",
	storageBucket: "stgclasscloud.appspot.com",
	messagingSenderId: "386671198561",
	appId: "1:386671198561:web:5ee715e2eae05b6aee8520",
	measurementId: "G-JW9EK018NQ"
}

export const PROD_FIREBASE_CONFIG: FirebaseConfig = {
	apiKey: "AIzaSyDmBiZQSPOBwymB9P8_vL1HmMXKxQcwf2I",
	authDomain: "auth.class-cloud.jp",
	projectId: "classcloud-96ea2",
	storageBucket: "classcloud-96ea2.appspot.com",
	messagingSenderId: "711108803505",
	appId: "1:711108803505:web:a5f5a407a28e012ea71b90",
	measurementId: "G-VEKN0RNTJX"
}
