import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type CourseType = {
	currentCourseId: string | undefined
}

const initialState: CourseType = {
	currentCourseId: undefined,
}

const slice = createSlice({
	name: "course",
	initialState,
	reducers: {
		setCurrentCourseId: (state: CourseType, action: PayloadAction<string>) => {
			return { ...state, currentCourseId: action.payload }
		},
	},
})

export const courseReducer = slice.reducer
export const { setCurrentCourseId } = slice.actions
