import { useEffect } from "react"

const THRESHOLD = 1.5
const TIMEOUT = 100

type Params = {
	onShake: () => void
}
export const useOnShake = ({ onShake }: Params) => {
	let lastX = 0,
		lastY = 0,
		lastZ = 0
	let lastUpdate = 0

	const handleAcceleration = (
		acceleration: DeviceMotionEventAccelerationInit,
	) => {
		const timeDiff = Date.now() - lastUpdate
		const aX = acceleration.x || 0
		const aY = acceleration.y || 0
		const aZ = acceleration.z || 0

		if (TIMEOUT < timeDiff) {
			const deltaX = aX - lastX
			const deltaY = aY - lastY
			const deltaZ = aZ - lastZ

			const delta = Math.abs(deltaX + deltaY + deltaZ)

			if (THRESHOLD < delta) {
				onShake()
			}
			lastX = aX
			lastY = aY
			lastZ = aZ
			lastUpdate = Date.now()
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined" && "DeviceMotionEvent" in window) {
			window.addEventListener("devicemotion", (event) => {
				event.accelerationIncludingGravity &&
					handleAcceleration(event.accelerationIncludingGravity)
			})
		}

		return () => {
			if (typeof window !== "undefined" && "DeviceMotionEvent" in window) {
				window.removeEventListener("devicemotion", (event) => {
					event.accelerationIncludingGravity &&
						handleAcceleration(event.accelerationIncludingGravity)
				})
			}
		}
	}, [])
}
