import { useEffect, useState } from "react"

export const useAppVisibility = () => {
	const [isVisible, setIsVisible] = useState(true)
	useEffect(() => {
		document.addEventListener("visibilitychange", detectVisibilityChange)
		return () =>
			document.removeEventListener("visibilitychange", detectVisibilityChange)
	}, [])

	useEffect(() => {
		window.addEventListener("focus", detectFocused)
		window.addEventListener("blur", detectBlur)
		return () => {
			window.removeEventListener("focus", detectFocused)
			window.removeEventListener("blur", detectBlur)
		}
	}, [])

	const detectFocused = () => {
		setIsVisible(true)
	}
	const detectBlur = () => {
		setIsVisible(true)
	}

	const detectVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			setIsVisible(true)
		}
		if (document.visibilityState === "hidden") {
			setIsVisible(false)
		}
	}

	return { isVisible }
}
