import camelcaseKeys from "camelcase-keys"
import snakecaseKeys from "snakecase-keys"

export const convertToCamelCase = (obj: any): any => {
	try {
		return camelcaseKeys(obj, { deep: true })
	} catch (e) {
		return obj
	}
}

export const convertToSnakeCase = (obj: any): any => {
	try {
		return snakecaseKeys(obj, { deep: false })
	} catch (e) {
		return obj
	}
}

export const convertToSnakeCaseString = (str: string): string => {
	const tempObj = {} as { [key in string]: any }
	tempObj[str] = true
	const snakeKeyObj = snakecaseKeys(tempObj)
	const snakeCaseString = Object.keys(snakeKeyObj)[0]
	return snakeCaseString || "errorOcurred"
}
