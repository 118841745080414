import { stringToArray } from "@helpers/stringToArray"

export const encodeWithShift = (val: any, shiftNum: number) => {
	const str = JSON.stringify(val)
	const encoded_str = encodeURIComponent(str)
	const encoded_val = stringToArray(encoded_str).map((c) =>
		String.fromCharCode(c.charCodeAt(0) + shiftNum)
	)
	return encoded_val.join("")
}

export const decodeWithShift = (str: string, shiftNum: number) => {
	const encoded_str = stringToArray(str)
		.map((c) => String.fromCharCode(c.charCodeAt(0) - shiftNum))
		.join("")
	const decodedStr = decodeURIComponent(encoded_str)
	const obj = JSON.parse(decodedStr)
	return obj
}

export const ccDecrypt = (str: string) => {
	let result = str
	;[5, 4, 3, 2, 1].forEach((shiftNum) => {
		result = decodeWithShift(result, shiftNum)
	})
	return result as any
}

export const ccEncrypt = (val: any) => {
	let result = val
	;[1, 2, 3, 4, 5].forEach((shiftNum) => {
		// 2回目以降、分ける意味ないけど
		result = encodeWithShift(result, shiftNum)
	})
	return result as any
}
