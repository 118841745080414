import { DefaultApi } from "@apis/typescript-axios"
import { Flex, Text, VStack } from "@chakra-ui/react"
import { ScrollableModalContent } from "@components/ScrollableModalContent"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { GoogleButton } from "@features/sso/google/GoogleButton"
import { MicrosoftButton } from "@features/sso/microsoft/MicrosoftButton"
import {
	GOOGLE_PROVIDER_META,
	MICROSOFT_PROVIDER_META,
} from "@features/sso/providers"
import { useConnectToSSOAccount } from "@features/sso/useConnectToSSOAccount"
import { useGetAuthProviderId } from "@features/sso/useGetAuthProviderId"
import { useModal } from "@hooks/useModal"
import { useUser } from "@hooks/useUser"
import { colors } from "@styles/colors"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useForceSSOConnectModal = () => {
	const { ModalWrapper, openModal, closeModal } = useModal({
		modalProps: {
			closeOnOverlayClick: false,
			closeOnEsc: false,
		},
	})
	const [modalIsShown, setModalIsShown] = useState(false)
	const { data: authProviderData } = useGetAuthProviderId()
	const user = useUser()

	useEffect(() => {
		if (!authProviderData) return
		if (modalIsShown) return
		if (authProviderData.userAuthProviderId) return
		if (!authProviderData.schoolAuthProviderId) return
		openModal()
		setModalIsShown(true)
	}, [authProviderData])

	const reportDismissModal = useCallback(() => {
		try {
			new DefaultApi().postProxySlackApi({
				slackUrl:
					"https://hooks.slack.com/services/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",
				text:
					"モーダルを閉じました" +
					JSON.stringify({
						role: user?.roleType,
						name: user?.fullName,
						userId: user?.id,
						roleId: user?.roleId,
					}),
			})
		} catch (error) {
			console.error(error)
		}
	}, [user])

	const reportConnect = useCallback(() => {
		try {
			new DefaultApi().postProxySlackApi({
				slackUrl:
					"https://hooks.slack.com/services/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",
				text:
					"☀️連携しました！" +
					JSON.stringify({
						role: user?.roleType,
						name: user?.fullName,
						userId: user?.id,
						roleId: user?.roleId,
					}),
			})
		} catch (error) {
			console.error(error)
		}
	}, [user])

	const { updateFirebaseUser, isUpdatingFirebaseUser } = useConnectToSSOAccount(
		{
			onSuccess: () => {
				reportConnect()
				closeModal()
			},
		},
	)

	const GoogleContent = useMemo(
		() => (
			<VStack spacing={4}>
				<TextWithFurigana
					color={colors.ui.accent[500]}
					fontWeight={"bold"}
					fontSize={"lg"}
					furigana="したのぼたんをおしてぐーぐるとれんけいしてください"
				>
					下のボタンを押してGoogleと連携してください
				</TextWithFurigana>
				<GoogleButton
					onClick={() => updateFirebaseUser(GOOGLE_PROVIDER_META)}
					isLoading={isUpdatingFirebaseUser}
					alignSelf="center"
				>
					Googleアカウントと連携させる
				</GoogleButton>
			</VStack>
		),

		[updateFirebaseUser, isUpdatingFirebaseUser],
	)

	const MicrosoftContent = useMemo(
		() => (
			<VStack spacing={4}>
				<TextWithFurigana
					color={colors.ui.accent[500]}
					fontWeight={"bold"}
					fontSize={"lg"}
					furigana="したのぼたんをおしてまいくろそふととれんけいしてください"
				>
					下のボタンを押してMicrosoftと連携してください
				</TextWithFurigana>
				<MicrosoftButton
					onClick={() => updateFirebaseUser(MICROSOFT_PROVIDER_META)}
					isLoading={isUpdatingFirebaseUser}
					alignSelf="center"
				>
					Microsoftアカウントと連携させる
				</MicrosoftButton>
			</VStack>
		),

		[updateFirebaseUser, isUpdatingFirebaseUser],
	)

	const Content = useMemo(
		() => (
			<VStack spacing={3} p={10}>
				<ScrollableModalContent
					onCloseButtonClick={() => {
						reportDismissModal()
						closeModal()
					}}
				>
					{authProviderData?.schoolAuthProviderId === "google.com"
						? GoogleContent
						: authProviderData?.schoolAuthProviderId === "microsoft.com"
						? MicrosoftContent
						: null}
					<Flex
						flexDirection={{ base: "column", md: "row" }}
						pt={10}
						flexWrap={"wrap"}
						alignSelf={"center"}
						w="100%"
						maxW={"600px"}
					>
						<Text>
							連携されない限り、この画面はアプリを開く度に表示されます。
						</Text>
						<Text>どうしても連携できない場合は</Text>
						<Text fontWeight={"bold"}>
							{
								"マイページ > 右上の三本線のメニュー > 開発者にチャットでお問い合わせ"
							}
						</Text>
						<Text>からお問い合わせください。</Text>
					</Flex>
				</ScrollableModalContent>
			</VStack>
		),
		[
			reportDismissModal,
			closeModal,
			updateFirebaseUser,
			isUpdatingFirebaseUser,
			authProviderData,
		],
	)

	const Modal = useMemo(
		() => <ModalWrapper hideCloseButton>{Content}</ModalWrapper>,
		[ModalWrapper, Content],
	)

	const shouldShowModal =
		!user?.isDemoUser &&
		(user?.isStudent || user?.isTeacher) &&
		(!window.ReactNativeWebView || !window.classcloud?.tokens)

	return {
		Modal: shouldShowModal ? Modal : null,
	}
}
