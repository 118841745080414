import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type GlobalVideoStopStateType = {
	timestamp: number | undefined
}

const initialState: GlobalVideoStopStateType = {
	timestamp: undefined,
}

const slice = createSlice({
	name: "globalVideoStop",
	initialState,
	reducers: {
		stopAllVideo: () => {
			return { timestamp: new Date().getTime() }
		},
	},
})

export const globalVideoStopReducer = slice.reducer
export const { stopAllVideo } = slice.actions
