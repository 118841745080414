import useSWR from "swr"

const KEY = "/api/v1/users/me/classrooms_properties_string"

type Params = {
	isLoggedIn: boolean
}

export const useOwnClassroomPropertiesString = ({ isLoggedIn }: Params) => {
	const { data, mutate } = useSWR<{ propertiesString: string }>(
		isLoggedIn ? KEY : null
	)
	return { data, mutate }
}
