import { useCallback, useRef } from "react"

type Params = {
	intervalMs: number
}

export const useShouldRefetch = ({ intervalMs }: Params) => {
	const lastFetchedAtRef = useRef<Date>()

	const getShouldFetch = useCallback(() => {
		const passedTime = lastFetchedAtRef.current
			? new Date().getTime() - lastFetchedAtRef.current.getTime()
			: undefined
		const shouldFetch = passedTime !== undefined && intervalMs < passedTime
		return shouldFetch
	}, [intervalMs])

	const setLastFetchedAt = useCallback(() => {
		lastFetchedAtRef.current = new Date()
	}, [])

	return {
		getShouldFetch,
		setLastFetchedAt,
	}
}
