import { useConst } from "@chakra-ui/react"
import { CCLocalStorage } from "@utils/CCLocalStorage"
import { CCSessionStorage } from "@utils/CCSessionStorage"
import { useCallback } from "react"
import { ENVIRONMENT } from "../../env_config"

export const useIsTestMode = () => {
	const isTestMode = useConst(() => {
		const isTestModeFromStorage =
			ENVIRONMENT === "local"
				? CCLocalStorage.getItem("isTestMode", false)
				: CCSessionStorage.getItem("isTestMode", false)
		return isTestModeFromStorage
	})
	const toggleTestMode = useCallback(() => {
		const isTestModeFromStorage =
			ENVIRONMENT === "local"
				? CCLocalStorage.getItem("isTestMode", false)
				: CCSessionStorage.getItem("isTestMode", false)
		ENVIRONMENT === "local"
			? CCLocalStorage.setItem("isTestMode", !isTestModeFromStorage)
			: CCSessionStorage.setItem("isTestMode", !isTestModeFromStorage)
		window.alert(
			`テストモードを${isTestModeFromStorage ? "オフ" : "オン"}にしました。`,
		)
	}, [])

	return {
		isTestMode,
		toggleTestMode,
	}
}
