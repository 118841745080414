import { useEffect, useRef } from "react"

export const useCallOnceOnMount = (callback: () => void) => {
	const hasCalledRef = useRef(false)
	useEffect(() => {
		if (!hasCalledRef.current) {
			const returns = callback()
			hasCalledRef.current = true
			return returns
		}
	}, [])
}
