import { Button, ButtonProps, HStack, Text } from "@chakra-ui/react"
import { useIsTestMode } from "@hooks/useIsTestMode"
import { FC } from "react"

type Props = {
	children: React.ReactNode
	onClick: () => void
	isLoading: boolean
} & ButtonProps

export const MicrosoftButton: FC<Props> = ({
	children,
	onClick,
	isLoading,
	...restButtonProps
}) => {
	const { isTestMode } = useIsTestMode()
	return (
		<Button
			// display={isTestMode ? "inline-block" : isProd ? "none" : "inline-block"}
			bg="#fff"
			px={0}
			color="#5e5e5e"
			borderColor="#8c8c8c"
			borderWidth={1}
			size="lg"
			fontWeight="medium"
			_hover={{
				// bg: "#f5f5f5"
				opacity: 0.7
			}}
			fontFamily="Roboto"
			onClick={onClick}
			isLoading={isLoading}
			{...restButtonProps}
		>
			<HStack>
				<img
					src={"/images/microsoft_logo_sso.svg"}
					style={{ marginLeft: 12 }}
				/>
				<Text px={6}>{children}</Text>
			</HStack>
		</Button>
	)
}
