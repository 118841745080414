import { Box, Text, ToastId, UseToastOptions, useToast } from "@chakra-ui/react"
import { useCallback } from "react"
// import { ToastContext } from "@contexts/ToastContext"
import { ToastType } from "@type/models/Toast"

type PromiseOptions = {
	success: { title: string; description: string }
	error: { title: string; description: string }
	loading: { title: string; description: string }
}

// const toast = createStandaloneToast()

export type { ToastId }

const renderCongratsToastFactory = (text?: string) => () => {
	return (
		<Box rounded="md">
			<Text whiteSpace="pre-line">{text}</Text>
		</Box>
	)
}

const argsToChakraToastParamsForCongrats = (
	args: Partial<ToastType>,
): UseToastOptions => ({
	title: args.text,
	// description: "",
	render: renderCongratsToastFactory(args.text),
	position: args.position === "bottom" ? "bottom-right" : "top-right",
	duration: args.duration,
	isClosable: true,
	containerStyle: { whiteSpace: "pre-line" },
})

const argsToChakraToastParams = (
	args: Partial<ToastType>,
): UseToastOptions => ({
	title: args.text,
	// description: "",
	status: args.type === "warn" ? "warning" : args.type ? args.type : "success",
	position: args.strictPosition
		? args.strictPosition
		: args.position === "bottom"
		? "bottom-right"
		: "top-right",
	duration: args.duration,
	isClosable: true,
	containerStyle: { whiteSpace: "pre-line" },
})

export const useCCToast = () => {
	const toast = useToast()
	// const { addToast } = useContext(ToastContext)
	const closeToast = useCallback(
		(toastId: ToastId) => {
			toast.close(toastId)
		},
		[toast],
	)
	const addToast = useCallback((args: Partial<ToastType>) => {
		const toastId = toast(argsToChakraToastParams(args))
		return toastId
	}, [])

	const addCongratsToast = useCallback((args: Partial<ToastType>) => {
		toast({
			title: args.text,
			// description: "",
			render: renderCongratsToastFactory(args.text),
			position: args.position === "bottom" ? "bottom-right" : "top-right",
			duration: args.duration,
			isClosable: true,
			containerStyle: { whiteSpace: "pre-line" },
		})
	}, [])

	const updateToast = useCallback(
		(toastId: ToastId, args: Partial<ToastType>) => {
			toast.update(toastId, argsToChakraToastParams(args))
		},
		[toast],
	)

	const addPromiseToast = useCallback(
		(promise: Promise<any>, options: PromiseOptions) => {
			toast.promise(promise, options)
		},
		[toast],
	)

	return {
		closeToast,
		addToast,
		addCongratsToast,
		updateToast,
		addPromiseToast,
	}
}
