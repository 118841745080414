import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import produce from "immer"
import { CommentFormMedia } from "@type/models/Media"

export type SingleCommentFormContent = {
	content: {
		text?: string
		file?: CommentFormMedia // 一応書いてあるだけで使う予定なし
	}
	lastEditedAt: string
}

export type CommentFormContentDict = {
	[key in string]: SingleCommentFormContent
} // このkeyはpostId

const initialState: CommentFormContentDict = {}

const slice = createSlice({
	name: "commentFormContent",
	initialState,
	reducers: {
		addOrUpdateFormContent: (
			state: CommentFormContentDict,
			action: PayloadAction<{
				id: string
				formContent: SingleCommentFormContent
			}>
		) => {
			const { id, formContent } = action.payload
			const nextState = produce(state, (draftState) => {
				draftState[id] = formContent
			})
			return nextState
		},
	},
})

export const commentFormContentReducer = slice.reducer
export const { addOrUpdateFormContent } = slice.actions
