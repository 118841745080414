// import NetInfo from "@react-native-community/netinfo"
import { useCCToast } from "@hooks/useCCToast"
import { isHandleableServerError } from "@type/models/Error"
import { ToastType } from "@type/models/Toast"
import { AxiosError } from "axios"

const DEFAULT_DIRECTIVE = {
	500: "サーバーエラーが起きました。",
	502: "サーバーエラーが起きました。\nお手数ですが、時間をおいてお試しください",
	503: "サーバーエラーが起きました。\nお手数ですが、時間をおいてお試しください",
	504: "サーバーエラーが起きました。\nお手数ですが、時間をおいてお試しください",
}

type ErrorDirective = {
	[key in number]: string
}

type AddErrorToastParams = {
	error: any
	additionalTextDirective?: ErrorDirective
	baseText?: string
	toastProps?: Partial<ToastType>
	addServerErrorMessages?: boolean
	statusCodesToIgnore?: number[]
}

export const useErrorToast = () => {
	const { addToast } = useCCToast()
	const addErrorToast = async ({
		error,
		additionalTextDirective: directive,
		baseText,
		toastProps,
		addServerErrorMessages,
		statusCodesToIgnore,
	}: AddErrorToastParams) => {
		if (!isHandleableServerError(error)) {
			await showOfflineToast()
			return
		}
		if (statusCodesToIgnore?.includes(error?.response?.status)) {
			return
		}
		console.warn(error.response.data, (error as AxiosError)?.config)

		const statusCode = error.response.status
		directive = {
			...DEFAULT_DIRECTIVE,
			...directive,
		}

		const additionalText = directive[statusCode]
			? `${directive[statusCode]}`
			: ""

		const serverMessages = addServerErrorMessages
			? (additionalText ? "\n" : "") +
			  Object.values(error.response?.data || {}).join("\n")
			: ""

		addToast({
			text: (baseText ? baseText + "\n" : "") + additionalText + serverMessages,
			type: "error",
			...toastProps,
		})
	}

	// TODO offlineの時対応
	const showOfflineToast = async () => {
		// const state = await NetInfo.fetch()
		// !state.isConnected &&
		// 	addToast({
		// 		text: "オフライン環境のようです\n接続をご確認ください",
		// 		type: "warn"
		// 	})
	}
	return { addErrorToast }
}
