import { Button, ButtonProps, HStack, Text } from "@chakra-ui/react"
import { FC } from "react"

type Props = {
	children: React.ReactNode
	onClick: () => void
	isLoading: boolean
} & ButtonProps

export const GoogleButton: FC<Props> = ({
	children,
	onClick,
	isLoading,
	...restButtonProps
}) => {
	return (
		<>
			<link
				href="https://fonts.googleapis.com/css?family=Roboto"
				rel="stylesheet"
				type="text/css"
			></link>
			<Button
				bg="#4285F4"
				px={0}
				color="#fff"
				size="lg"
				fontWeight="medium"
				_hover={{
					// bg: "#f5f5f5"
					opacity: 0.7
				}}
				fontFamily="Roboto"
				onClick={onClick}
				isLoading={isLoading}
				{...restButtonProps}
			>
				<HStack>
					<img src={"/images/btn_google_light_normal_ios.svg"} />
					<Text px={6}>{children}</Text>
				</HStack>
			</Button>
		</>
	)
}
