import { VStack, StackProps } from "@chakra-ui/react"
import React, { FC, RefObject } from "react"

export type CCVStackProps = StackProps & {
	_ref?: RefObject<typeof VStack | HTMLDivElement>
}

export const CCVStack: FC<CCVStackProps> = (props) => {
	return (
		<VStack
			ref={props._ref}
			spacing={0}
			justifyContent="flex-start"
			alignItems="flex-start"
			{...props}
		/>
	)
}
