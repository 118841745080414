import { useCourses } from "@hooks/useCourses"
import { useOwnClassroomPropertiesString } from "@hooks/useOwnClassroomsPropertiesString"
import { useShouldRefetch } from "@hooks/useShouldRefetch"
import { useSimpleCourse } from "@hooks/useSimpleCourse"
import { useUser } from "@hooks/useUser"
import * as Sentry from "@sentry/nextjs"
import { AnalyticsLog } from "@utils/analytics/amplitude"
import { useEffect } from "react"

export const useSetUserPropertiesToLogger = () => {
	const { getShouldFetch, setLastFetchedAt } = useShouldRefetch({
		intervalMs: 1000 * 60 * 3,
	})
	const user = useUser()
	const { currentCourseId } = useCourses()
	const { course } = useSimpleCourse(currentCourseId)
	const {
		data: classroomsPropertiesString,
		mutate: refreshOwnClassroomProperties,
	} = useOwnClassroomPropertiesString({ isLoggedIn: !!user?.roleId })

	useEffect(() => {
		if (!user) return
		if (getShouldFetch()) {
			refreshOwnClassroomProperties()
			setLastFetchedAt()
		}
	}, [user])

	useEffect(() => {
		if (!user) return
		const userInfo = {
			id: user.id,
			roleId: user.roleId,
			roleType: user.roleType,
			fullName: user.fullName,
			currentCourse: course,
			classroomsPropertiesString: classroomsPropertiesString?.propertiesString,
		}
		AnalyticsLog.setUserPropertiesToLogger(userInfo)
		Sentry.setUser(userInfo)
	}, [user, course, classroomsPropertiesString?.propertiesString])
}
