import { useStoreQueryStrings } from "@hooks/useStoreQueryStrings"
import { useRouter } from "next/router"
import { useCallback } from "react"

export const useQueryStrings = () => {
	const router = useRouter()
	const storeQueryString = useStoreQueryStrings()

	const setUrlQueryParams = useCallback(
		async ({ key, value }: { key: string; value: string }) => {
			const currentUrl = window.location.href
			const urlObj = new URL(currentUrl)
			urlObj.searchParams.set(key, value)
			await router.replace(urlObj.href, undefined, { scroll: false })
		},
		[router],
	)

	const addUrlQueryParams = useCallback(
		async ({
			key,
			value,
			shouldReplaceValue = true,
		}: {
			key: string
			value: string
			shouldReplaceValue?: boolean
		}) => {
			// const currentUrl = window.location.href
			// const urlObj = new URL(currentUrl)
			// if (shouldReplaceValue) {
			// 	urlObj.searchParams.delete(key)
			// 	urlObj.searchParams.append(key, value)
			// } else {
			// 	urlObj.searchParams.append(key, value)
			// }
			// console.log("urlObj.href", urlObj.href)

			// setTimeout(
			// 	async () =>
			// 		await router.replace(urlObj.href, undefined, { scroll: false }),
			// 	0
			// )
			storeQueryString.addQueryString({ key, value })
			// 以下だとダメ
			// router.replace(urlObj.href, undefined, { scroll: false })
			// queueMicrotask(() =>
			// 	router.replace(urlObj.href, undefined, { scroll: false })
			// )
		},
		[router],
	)

	const removeUrlQueryParams = useCallback(
		async ({ key }: { key: string }) => {
			// 	const currentUrl = window.location.href
			// 	const urlObj = new URL(currentUrl)
			// 	urlObj.searchParams.delete(key)
			// 	await router.replace(urlObj.href, undefined, { scroll: false })
			storeQueryString.removeQueryString({ key })
		},
		[router],
	)

	return {
		setUrlQueryParams,
		addUrlQueryParams,
		removeUrlQueryParams,
	}
}
