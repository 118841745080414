import { useCallback } from "react"
import { add, remove } from "@stores/queryString"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@stores/index"

export const useStoreQueryStrings = () => {
	const queryString = useSelector((state: RootState) => state.queryString)
	const dispatch = useDispatch()
	const addQueryString = useCallback(
		({
			key,
			value,
			shouldReplaceValue = true
		}: {
			key: string
			value: string
			shouldReplaceValue?: boolean
		}) => {
			dispatch(add({ key, value }))
		},
		[]
	)

	const removeQueryString = useCallback(({ key }: { key: string }) => {
		dispatch(remove({ key }))
	}, [])

	return {
		addQueryString,
		removeQueryString,
		queryString
	}
}
