import { Media } from "@features/mediaComparison/MediaComparisonBox"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type MediaListForComparisonType = {
	mediaList: Media[]
}

const initialState: MediaListForComparisonType = {
	mediaList: []
}

const slice = createSlice({
	name: "mediaListForComparison",
	initialState,
	reducers: {
		appendMedia: (
			state: MediaListForComparisonType,
			action: PayloadAction<Media>
		) => {
			const newMedia = action.payload
			return { ...state, mediaList: [...state.mediaList, newMedia] }
		},
		removeMedia: (
			state: MediaListForComparisonType,
			action: PayloadAction<string>
		) => {
			const mediaId = action.payload
			return {
				...state,
				mediaList: state.mediaList.filter((m) => m.id !== mediaId)
			}
		},
		clearMediaList: (
			state: MediaListForComparisonType,
			action: PayloadAction
		) => {
			return {
				...state,
				mediaList: []
			}
		}
	}
})

export const mediaListForComparisonReducer = slice.reducer
export const { appendMedia, removeMedia, clearMediaList } = slice.actions
