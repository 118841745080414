import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CourseSimpleRead } from "@type/models/Course"
import { PostTimelineRead } from "@type/models/Post"

export type LocalPostViewType = {
	postView: { [key in PostTimelineRead["id"]]: string } // key にpostId、valueにそれをみた最終時刻のstring
	newPostViewCounts: {
		[key in CourseSimpleRead["id"]]: number // key にcourseId、valueに既読になった数
	}
}

const initialState: LocalPostViewType = {
	postView: {},
	newPostViewCounts: {},
}

const slice = createSlice({
	name: "localPostView",
	initialState,
	reducers: {
		appendLocalPostView: (
			state: LocalPostViewType,
			action: PayloadAction<{ postId: string }>
		) => {
			const { postId } = action.payload
			state.postView[postId] = new Date().toISOString()
			return state
		},
		incrementNewPostViewCountForCourse: (
			state: LocalPostViewType,
			action: PayloadAction<{ courseId: string }>
		) => {
			const { courseId } = action.payload
			const prevCount = state.newPostViewCounts[courseId] || 0
			state.newPostViewCounts[courseId] = prevCount + 1
			return state
		},
		resetNewPostViewCount: (
			state: LocalPostViewType,
			action: PayloadAction
		) => {
			state.newPostViewCounts = {}
			return state
		},
	},
})

export const localPostViewReducer = slice.reducer
export const {
	appendLocalPostView,
	incrementNewPostViewCountForCourse,
	resetNewPostViewCount,
} = slice.actions
