import { isProd } from "@constants/const"
import { windowExists } from "@helpers/windowExists"
import { default as NextHead } from "next/head"

type Props = {}

export const Head = ({}: Props) => {
	const shouldIndex =
		isProd &&
		windowExists() &&
		(window.location.pathname === "/" || window.location.pathname === "")

	return (
		<NextHead>
			<meta
				name="viewport"
				content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
			/>
			{shouldIndex ? (
				<meta key="robots" name="robots" content="max-image-preview:large" />
			) : (
				<meta
					key="robots"
					name="robots"
					content="max-image-preview:large,noindex"
				/>
			)}
		</NextHead>
	)
}
