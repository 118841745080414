import { StackProps, Text, TextProps, VStack } from "@chakra-ui/react"
import { areObjectsEqual } from "@helpers/areObjectsEqual"
import { useUser } from "@hooks/useUser"
import { CSSProperties, FC, memo, useMemo } from "react"

export type TextWithFuriganaProps = {
	furigana: string
	furiganaProps?: TextProps
	color?: TextProps["color"]
	stackProps?: StackProps
	align?: "left" | "right" | "center"
	size?: "xl" | "lg" | "md" | "sm" | "xs"
	bold?: boolean
} & Omit<TextProps, "size">

const DEFAULT_FURIGANA_PROPS: TextProps = {
	fontSize: 8,
	alignItems: "center",
	mb: 0,
	lineHeight: "9px",
}

const DEFAULT_TEXT_PROPS: TextProps = {}

const TextWithFurigana: FC<TextWithFuriganaProps> = memo(
	({
		furigana,
		color,
		furiganaProps,
		children,
		stackProps,
		align = "center",
		size = "md",
		bold = false,
		...textProps
	}) => {
		size = size || textProps.fontSize
		const user = useUser()
		const showFurigana = !user || (user?.shouldShowFurigana && !!furigana)

		const vStackStyle = useMemo(
			() => ({
				translateY: showFurigana ? getTranslateY(size) : 0,
			}),
			[showFurigana, size],
		) as CSSProperties

		return (
			<VStack style={vStackStyle} {...stackProps} spacing={0}>
				{showFurigana && (
					<Text
						{...DEFAULT_FURIGANA_PROPS}
						color={color}
						fontWeight={bold ? "bold" : "normal"}
						fontSize={getFuriganaSize(size)}
						textAlign={align}
						mb={getFuriganaMarginBottom(size)}
						w="100%"
						wordBreak={"keep-all"}
						{...furiganaProps}
					>
						{/* ふりがなにスペースが入るとこのTextの高さが変わるのみならずtextのベースラインも変わってしまうので、もはや最初から入れる */}
						{align === "left"
							? `${furigana} `
							: align === "right"
							? ` ${furigana}`
							: ` ${furigana} `}
					</Text>
				)}
				<Text
					color={color}
					fontWeight={bold ? "bold" : "normal"}
					{...DEFAULT_TEXT_PROPS}
					fontSize={size}
					lineHeight={getLineHeight(size)}
					textAlign={align}
					w="100%"
					{...textProps}
				>
					{children}
				</Text>
			</VStack>
		)
	},
	areObjectsEqual,
)
TextWithFurigana.displayName = "TextWithFurigana"
export { TextWithFurigana }

const getLineHeight = (
	textSize: TextWithFuriganaProps["size"],
): TextProps["fontSize"] => {
	return 1.5
	switch (textSize) {
		case "xl":
			return "32px"
		case "lg":
			return "28px"
		case "md":
			return "24px"
		case "sm":
			return "16px"
		case "xs":
			return "12px"
		default:
			return "12px"
	}
}

const getFuriganaSize = (
	textSize: TextWithFuriganaProps["size"],
): TextProps["fontSize"] => {
	switch (textSize) {
		case "xl":
			return "sm"
		case "lg":
			return "xs"
		case "md":
			return "8px"
		case "sm":
			return "8px"
		case "xs":
			return "8px"
		default:
			return "xs"
	}
}

const getFuriganaMarginBottom = (
	textSize: TextWithFuriganaProps["size"],
): TextProps["mb"] => {
	switch (textSize) {
		case "xl":
			return 0
		case "lg":
			return 0
		case "md":
			return -0.5
		case "sm":
			return -0.5
		case "xs":
			return -0.5
		default:
			return -0.5
	}
}

const getTranslateY = (textSize: TextWithFuriganaProps["size"]): number => {
	switch (textSize) {
		case "xl":
			return -2
		case "lg":
			return -2
		case "md":
			return -1
		case "sm":
			return 0
		case "xs":
			return 0
		default:
			return -2
	}
}
