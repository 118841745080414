import { useSelector } from "react-redux"
import { RootState } from "@stores/index"
import { UserInfoType } from "@stores/userInfo"
import { UserAuthType } from "@type/models/User"

const isAuthenticated = (
	user: UserInfoType
): user is Omit<UserAuthType, "tokens"> => !!(user as any).user

export const useUser = () => {
	const userInfo = useSelector((state: RootState) => state.userInfo)
	return isAuthenticated(userInfo) ? userInfo.user : undefined
}
