import { CONFIG } from "@constants/const"
import { useCallOnceOnMount } from "@hooks/useCallOnceOnMount"
import { AnalyticsLog } from "@utils/analytics/amplitude"
import { CCLocalStorage } from "@utils/CCLocalStorage"

export const useInitAnalytics = () => {
	useCallOnceOnMount(() => {
		initIfRequired()
		optInOrOut()
	})

	const initIfRequired = () => {
		const AMPLITUDE_ID = CONFIG.AMPLITUDE_SDK_ID
		AMPLITUDE_ID && AnalyticsLog.init(AMPLITUDE_ID)
	}
	const optInOrOut = () => {
		const shouldOptOut = CCLocalStorage.getItem("shouldOptOutAnalytics", false)
		shouldOptOut ? AnalyticsLog.optOut() : AnalyticsLog.optIn()
	}
}
