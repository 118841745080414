import {
	Alert,
	ButtonProps,
	extendTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react"
import { colors } from "@styles/colors"
import { ComponentProps } from "react"
import { breakpoints } from "./breakpoints"

export const customTheme = extendTheme(
	{
		colors: {
			primary: {
				...colors.ui.primary,
			},
			accent: {
				...colors.ui.accent,
			},
			destructive: {
				...colors.ui.destructive,
			},
			ui: {
				primary: colors.ui.primary,
				accent: colors.ui.accent,
				gray: colors.ui.gray,
			},
			border: {
				primary: colors.border.primary,
				black: colors.border.black,
				gray: colors.border.gray,
			},
			text: {
				black: colors.text.black,
			},
			system: {
				required: "#ff0000",
				error: colors.system.error,
				warning: colors.system.warning,
				info: colors.system.info,
				success: colors.system.success,
				disabled: colors.system.disabled,
			},
		},
		components: {
			Text: {
				sizes: {
					"2xs": {
						fontSize: "8px",
						lineHeight: "10px",
					},
				},
			},
			Spinner: {
				baseStyle: {
					color: colors.text.black[400],
				},
			},
			IconButton: {
				baseStyle: {
					cursor: "pointer",
					_hover: { bg: colors.blackAlpha[200] },
				},
				sizes: {
					lg: {
						height: "100px",
						width: "100px",
					},
				},
				variants: {
					solid: (props: any) => ({
						bg: props.colorMode === "dark" ? "red.300" : "red.500",
						// _hover: { bg: "primary.300" }
						_hover: { bg: colors.blackAlpha[200] },
					}),
				},
			},
			FormLabel: {
				baseStyle: {
					fontWeight: "bold",
					fontSize: "sm",
				},
			},
			Button: {
				// baseStyle: {
				// 	_active: { bg: "rgb(255,0,0)" }
				// },
				variants: {
					solid: (props: ButtonProps) => ({
						_active: {
							bg: "primary.300",
							opacity: 0.7,
						},
						_hover: {
							bg: "primary.500",
							opacity: 0.7,
						},
					}),
					outline: (props: ButtonProps) => ({
						_active: {
							bg: "primary.300",
							opacity: 0.7,
						},
						// _hover: {
						// 	bg: "primary.500",
						// 	opacity: 0.7
						// }
						_disabled: {
							// bg: colors.text.black[400],
							// color: "#fff",
							opacity: 0.5,
						},
						_hover: { _disabled: { opacity: 0.5 } },
					}),
					dark: (props: ButtonProps) => ({
						color: "#fff",
						_active: {
							bg: colors.bg.gray[700],
							opacity: 0.7,
						},
						// _hover: {
						// 	bg: "primary.500",
						// 	opacity: 0.7
						// }
						_disabled: {
							bg: colors.text.black[400],
							color: "#fff",
							opacity: 0.4,
						},
						_hover: {
							bg: colors.bg.gray[700],
							_disabled: { bg: colors.ui.primary[700], color: "#fff" },
						},
					}),
					darkOutline: (props: ButtonProps) => ({
						borderWidth: 1,
						borderColor: colors.ui.primary[500],
						color: colors.ui.primary[500],
						_active: {
							bg: colors.bg.gray[700],
							opacity: 0.7,

							// _disabled: { bg: colors.text.black[400], opacity: 0.6 }
						},
						// _hover: {
						// 	bg: "primary.500",
						// 	opacity: 0.7
						// }
						_disabled: {
							bg: colors.text.black[400],
							color: "#fff",
							opacity: 0.4,
							borderColor: "rgba(0,0,0,0)",
							_hover: {
								bg: colors.text.black[400],
								opacity: 0.6,
							},
							_active: {
								bg: colors.text.black[400],
								opacity: 0.6,
							},
						},
						_hover: {
							bg: colors.bg.gray[700],
							_disabled: {
								bg: colors.text.black[400],
								opacity: 0.4,
								color: "#fff",
								borderColor: "rgba(0,0,0,0)",
							},
						},
					}),
					link: () => ({
						_active: {
							bg: "primary.300",
							opacity: 0.7,
						},
					}),
					cancel: () => ({
						_active: {
							bg: "primary.300",
							opacity: 0.7,
						},
						_hover: { bg: "blackAlpha.100" },
					}),
					destructive: () => ({
						bg: "destructive.500",
						color: "#fff",
						_active: {
							bg: "destructive.300",
							opacity: 0.7,
						},
						_hover: {
							bg: "destructive.300",
							opacity: 0.7,
							_disabled: {
								bg: "destructive.300",
								opacity: 1,
							},
						},
						_loading: { bg: "destructive.300" },
					}),
					flat: () => ({
						rounded: 0,
						w: "100%",
						_active: {
							bg: "primary.300",
							opacity: 0.7,
						},
						_hover: {
							bg: colors.bg.gray[100],
							opacity: 0.7,
						},
						_disabled: {
							bg: colors.text.black[400],
							color: "#fff",
							opacity: 0.4,
						},
					}),
				},
			},
			Input: {
				// baseStyle: {
				// 	paddingLeft: 20,
				// 	px: {
				// 		md: 100
				// 	},
				// 	sizes: {
				// 		lg: { px: 200 },
				// 		md: {
				// 			px: 200
				// 		}
				// 	}
				// },
				// sizes: {
				// 	lg: { px: 200 },
				// 	md: {
				// 		px: 200
				// 	}
				// }
			},
			Alert: {
				variants: {
					solid: (props: ComponentProps<typeof Alert>) => {
						return {
							// container: {
							bg: {
								success: colors.system.success,
								info: colors.system.info,
								warning: colors.system.warning,
								error: colors.system.error,
								loading: undefined,
							}[props.status || "info"],
							backgroundColor: {
								success: colors.system.success,
								info: colors.system.info,
								warning: colors.system.warning,
								error: colors.system.error,
								loading: undefined,
							}[props.status || "info"],
							// }
						}
					},
					subtle: (props: ComponentProps<typeof Alert>) => ({
						// container: {
						bg: {
							success: colors.system.success,
							info: colors.system.info,
							warning: colors.system.warning,
							error: colors.system.error,
							loading: undefined,
						}[props.status || "info"],
						backgroundColor: {
							success: colors.system.success,
							info: colors.system.info,
							warning: colors.system.warning,
							error: colors.system.error,
							loading: undefined,
						}[props.status || "info"],
						// }
					}),
				},
			},
			Link: {
				variants: {
					outline: {
						borderWidth: 1,
						borderRadius: 4,
						borderColor: colors.ui.primary[300],
						py: 2,
						px: 4,
					},
					solidButton: {
						borderRadius: 4,
						bg: colors.ui.primary[500],
						py: 2,
						px: 4,
						color: "#fff",
						fontWeight: 800,
					},
				},
			},
		},
		styles: {
			global: {
				"*": {
					WebkitOverflowScrolling: "touch",
				},
				// li: { lineHeight: "32px" }
				p: {
					whiteSpace: "pre-line",
				},
			},
		},
		breakpoints,
	},
	withDefaultColorScheme({
		colorScheme: "primary",
		components: ["Button", "Checkbox", "Radio"],
	}),
)
