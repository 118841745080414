import { extractQueryStringValueFromCurrentUrl } from "@helpers/extractQueryStringValue"
import { UserAuthType } from "@type/models/User"
import { useRouter } from "next/router"

const REDIRECT_FROM_PATHS_IF_LOGGED_IN = [
	"/",
	"/login",
	"/server_check",
	"/registration",
	"/email_verify",
	"/invited_guest_teacher",
	"/onetime_login_code_scan",
] // このパスにログイン状態でアクセスしてきたら、homeに飛ばす

export const NOT_REDIRECT_PATHS_EVEN_IF_NOT_LOGGED_IN = [
	"/password/reset",
	"/password/reset/confirm",
	"/sales_chat_bot",
	"/launch_app",
	"/email_verify",
	"/server_check",
	// "/invited_guest_teacher",
	"/about",
	// "/onetime_login_code_scan",
	"/terms",
	"/privacy_policy",
	// "/login"
]

export const useRedirectIfNeeded = () => {
	const router = useRouter()

	const redirect = (userAuth: UserAuthType) => {
		if (NOT_REDIRECT_PATHS_EVEN_IF_NOT_LOGGED_IN.includes(router.pathname)) {
			return
		}
		if (userAuth.user.isStudent) {
			redirectWhenStudent(userAuth)
		} else if (userAuth.user.isTeacher) {
			redirectWhenTeacher(userAuth)
		} else if (userAuth.user.isPatron) {
			redirectWhenPatron(userAuth)
		}
	}

	const redirectWhenStudent = (userAuth: UserAuthType) => {
		if (!userAuth?.user.roleId) {
			goToStudentProfileInput()
		} else if (userAuth?.student?.isPendingJoinClassroom) {
			goToStudentQR(userAuth)
		} else {
			goToContent()
		}
	}

	const redirectWhenTeacher = (userAuth: UserAuthType) => {
		if (!userAuth?.user.roleId) {
			goToTeacherProfileInput()
		} else {
			goToContent()
		}
	}
	const redirectWhenPatron = (userAuth: UserAuthType) => {
		if (!userAuth?.user.roleId) {
			goToPatronProfileInput()
		} else if (
			!userAuth?.patron?.isEmailVerified ||
			!userAuth?.patron?.hasAtLeastOneStudent
		) {
			goToPatronPendingSlide()
		} else {
			goToContent()
		}
	}

	const goToContent = () => {
		const currentPath = window.location.pathname
		const shouldRedirectToHome =
			REDIRECT_FROM_PATHS_IF_LOGGED_IN.includes(currentPath)
		const callbackPath = extractQueryStringValueFromCurrentUrl("callback")
		if (callbackPath?.split("/").includes("registration")) {
			router.push("/home")
			return
		}
		const shouldRedirectToCallback = callbackPath && currentPath === "/login"
		if (shouldRedirectToCallback) {
			router.push(callbackPath)
			return
		} else if (shouldRedirectToHome) {
			router.push("/home")
			return
		}
	}

	const goToTeacherProfileInput = () =>
		router.push("/registration/teacher/profile_input")

	const goToPatronProfileInput = () =>
		router.push("/registration/patron/profile_input")

	const goToPatronPendingSlide = () =>
		router.push("/registration/patron/pending")

	const goToStudentProfileInput = () =>
		router.push("/registration/student/profile_input")

	const goToStudentQR = (userAuth: UserAuthType) =>
		router.push(
			`/registration/student/wait_for_approval?student_id=${userAuth.user.roleId}`,
		)

	return { redirect }
}
