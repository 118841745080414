import React, {
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState
} from "react"
// import { LayoutAnimation, Platform } from "react-native"
import { ToastPosition, ToastSize, ToastType } from "@type/models/Toast"

const DefaultToastParams = {
	text: "",
	position: ToastPosition.CENTER,
	type: ToastType.INFO,
	visible: false,
	duration: 4000,
	size: ToastSize.MEDIUM,
	onDismiss: () => {}
}

type ToastContextType = {
	addToast: (args: Partial<ToastType>) => void
	toastList: ToastType[]
}

export const ToastContext = createContext({} as ToastContextType)

type ToastProviderProps = {
	children: JSX.Element
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
	const [toastList, setToastList] = useState<ToastType[]>([])
	const toastListRef = useRef(toastList)

	useEffect(() => {
		toastListRef.current = toastList
	}, [toastList])

	const addToast = useCallback((args: Partial<ToastType>) => {
		const toastId = new Date().getTime()
		const newToastList = [
			...toastListRef.current,
			{
				...DefaultToastParams,
				visible: true,
				toastId,
				...args,
				removeToast: () => removeToastByToastId(toastId)
			}
		]
		// LayoutAnimation.easeInEaseOut()
		setToastList(newToastList)
	}, [])

	const removeToastByToastId = useCallback((toastId: number) => {
		const newToastList = toastListRef.current.filter(
			(toast) => toast.toastId !== toastId
		)
		// Platform.OS === "ios" && LayoutAnimation.easeInEaseOut()
		setToastList(newToastList.slice())
	}, [])

	return (
		<ToastContext.Provider
			value={{
				addToast,
				toastList
			}}
		>
			{children}
		</ToastContext.Provider>
	)
}
