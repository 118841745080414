import { HomeClassroomSimpleRead } from "@type/models/Classroom"
import { StudentRoleRead } from "@type/models/Student"
import React, { FC, createContext, useState } from "react"

type StudentRegistrationContextType = {
	joinCode: string
	setJoinCode: (joinCode: string) => void
	classroom: HomeClassroomSimpleRead | undefined
	setClassroom: (classroom: HomeClassroomSimpleRead) => void
	studentId: StudentRoleRead["id"] | undefined
	setStudentId: (studentId: StudentRoleRead["id"]) => void
}

export const StudentRegistrationContext =
	createContext<StudentRegistrationContextType>(
		{} as StudentRegistrationContextType
	)

type Props = {
	children: React.ReactNode
}
export const StudentRegistrationContextProvider: FC<Props> = ({ children }) => {
	const [joinCode, setJoinCode] = useState("")
	const [studentId, setStudentId] = useState<StudentRoleRead["id"]>()
	const [classroom, setClassroom] = useState<HomeClassroomSimpleRead>() // ClassroomSimpleReadでも良いかも

	return (
		<StudentRegistrationContext.Provider
			value={{
				joinCode,
				setJoinCode,
				classroom,
				// classroom: classroom || dummyClassroom1, // TODO: デバッグ用
				setClassroom,
				studentId,
				setStudentId,
			}}
		>
			{children}
		</StudentRegistrationContext.Provider>
	)
}
