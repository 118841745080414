import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type NotificationType = {
	currentCourseId: string | undefined
}
const initialState: NotificationType = {
	currentCourseId: undefined,
}

const slice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setCurrentCourseId: (
			state: NotificationType,
			action: PayloadAction<string>,
		) => {
			return { ...state, currentCourseId: action.payload }
		},
	},
})

export const notificationReducer = slice.reducer
export const { setCurrentCourseId: setNotificationCurrentCourseId } =
	slice.actions
