import { firebaseAuth } from "@apis/firebase"
import { AccountsApi, DefaultApi } from "@apis/typescript-axios"
import { ProviderMeta } from "@features/sso/providers"
import { useCCToast } from "@hooks/useCCToast"
import { useErrorToast } from "@hooks/useErrorToast"
import { useUser } from "@hooks/useUser"
import { signInWithPopup } from "firebase/auth"
import { useCallback, useState } from "react"

type Params = {
	onSuccess?: () => void
}

export const useConnectToSSOAccount = ({ onSuccess }: Params) => {
	const [isUpdatingFirebaseUser, setIsUpdatingFirebaseUser] = useState(false)

	const { addToast } = useCCToast()
	const { addErrorToast } = useErrorToast()
	const user = useUser()

	const updateFirebaseUser = useCallback(
		async (providerMeta: ProviderMeta) => {
			if (isUpdatingFirebaseUser) return
			try {
				const provider = providerMeta.providerFactory()
				await signInWithPopup(firebaseAuth, provider)
				const idToken = await firebaseAuth.currentUser?.getIdToken()
				console.log(firebaseAuth.currentUser?.uid)
				if (idToken) {
					setIsUpdatingFirebaseUser(true)
					const res = await new AccountsApi().updateOwnFirebaseUser({
						idToken,
					})
				}
				addToast({ type: "success", text: "連携に成功しました" })
				onSuccess?.()
			} catch (err) {
				firebaseAuth.signOut()
				addErrorToast({
					error: err,
					baseText: "連携に失敗しました",
					addServerErrorMessages: true,
				})
				console.error(err)
				try {
					new DefaultApi().postProxySlackApi({
						slackUrl:
							"https://hooks.slack.com/services/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",
						text:
							"連携に失敗しました: " +
							JSON.stringify({
								role: user?.roleType,
								name: user?.fullName,
								userId: user?.id,
								roleId: user?.roleId,
								error: err,
							}),
					})
				} catch (reportError) {
					console.error(reportError)
				}
				// firebaseAuth.currentUser?.delete()
			} finally {
				setIsUpdatingFirebaseUser(false)
			}
		},
		[
			isUpdatingFirebaseUser,
			addToast,
			onSuccess,
			addErrorToast,
			setIsUpdatingFirebaseUser,
			user,
		],
	)

	return { updateFirebaseUser, isUpdatingFirebaseUser }
}
